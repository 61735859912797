body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.BuyTPDG {
  color: rgba(10, 2, 82, 1.00);
  background: linear-gradient(90deg, #FFFA6B 46%, #FB8F35 100%);
  text-align: center;
  padding: 15px 30px; /* Adjust padding as needed */
  border: none;
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* Shadow effect */
  transition: box-shadow 0.3s ease-in-out; 
}

.BuyTPDG:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.35); /* Shadow effect on hover */
}
.descTop{
	background: linear-gradient(90deg, rgba(136, 84, 243, 0.50) 0.0%, rgba(111, 107, 223, 1.00) 47.0%, rgba(79, 136, 199, 0.50) 100.0%);
}
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Custom scrollbar styles */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #b5b5bb #00015e;
}

/* For WebKit-based browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #333;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #333;
}

.hover-transform {
  transition: transform 0.3s ease;
}

.hover-transform:hover {
  transform: translateY(-10px);
}
.radiantBg{
 background:  linear-gradient(90deg, rgba(128, 49, 177, 1.00) 0.0%, rgba(111, 107, 224, 1.00) 41.5%, rgba(79, 199, 192, 1.00) 94.0%);
 padding-bottom: 10rem;
}
.join{
  background:  linear-gradient(90deg, rgba(128, 49, 177, 1.00) 0.0%, rgba(111, 107, 224, 1.00) 41.5%, rgba(79, 199, 192, 1.00) 94.0%);
 }
 .contactP input, .contactP textarea {
  background: transparent;
  font-size: 1.5rem;
  color: #fff;
  border: 2px solid white;
 }
 .text-stroke-red {
  -webkit-text-stroke: 3px #00005F;
  text-stroke: 3px #00005F; /* For compatibility with other browsers */
}
._106d2, ._SKh-V, ._2v-d4 {
  background: linear-gradient(158deg, rgba(128, 49, 177, 1.00) 0.0%, rgba(111, 107, 224, 1.00) 41.5%, rgba(79, 199, 192, 1.00) 94.0%)!important;
}

.list-star {
  list-style-type: none; /* Remove default bullets */
}

.list-star li::before {
  content: '☆'; /* Insert star symbol */
  color: text-[#FCE839]; /* Set the color */
  display: inline-block; 
  width: 1.2em; /* Give space between star and text */
  margin-left: -1em; /* Adjust position */
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}